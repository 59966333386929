import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';
import { isTokenValid, logout } from "../../store/actions/loginActions.js";
import { role } from "../../helpers/userInformation.js";
import { useNavigate } from 'react-router-dom';

const CustomNavbar = () => {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const accountData = useSelector(state => state.account);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = role();

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    dispatch(isTokenValid());
  }, []);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    updateNavbarColor();
    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const userLogout = () => {
    dispatch(logout());
    navigate('/', { replace: true });
  }

  return (
    <div id="section-index">
      <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              data-placement="bottom"
              title="Punta Sanat"
            //style={{ color: 'black' }}
            >
              Punta Sanat
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
          >
            <Nav navbar>
              <NavItem>
                <HashLink smooth to="/#section-index">
                  <h6 className="nav-link" style={{ color: navbarCollapse ? "black" : "" }}>Anasayfa</h6>
                </HashLink>
              </NavItem>
              <NavItem>
                <HashLink smooth to="/blogs-page#section-blogs">
                  <h6 className="nav-link" style={{ color: navbarCollapse ? "black" : "" }}>Bloglar</h6>
                </HashLink>
              </NavItem>
              <NavItem>
                <HashLink smooth to="/courses-page#section-courses">
                  <h6 className="nav-link" style={{ color: navbarCollapse ? "black" : "" }}>Kurslar</h6>
                </HashLink>
              </NavItem>
              <NavItem>
                <HashLink smooth to="/photo-gallery-page#section-gallery">
                  <h6 className="nav-link" style={{ color: navbarCollapse ? "black" : "" }}>Galeri</h6>
                </HashLink>
              </NavItem>
              {accountData.isAuthenticated === false ?
                <NavItem>
                  <Link to="/sign-in">
                    <Button
                      className="btn-round"
                      color="danger"
                    >
                      <i className="fa fa-user-circle-o" style={{ fontSize: "16px", marginRight: "8px" }}></i>Giriş Yap
                    </Button>
                  </Link>
                </NavItem>
                :
                <UncontrolledDropdown nav inNavbar>
                  <Button
                    className="btn-round"
                    color="danger"
                    style={{
                      minWidth: "128.58px",
                      minHeight: "41px"
                    }}
                  >
                    <DropdownToggle
                      style={{
                        padding: 0,
                        margin: 0,
                        color: "#FFF"
                      }}
                      aria-expanded={false}
                      aria-haspopup={true}
                      caret
                      color="white"
                      data-toggle="dropdown"
                      href="#"
                      id="dropdownMenuButton"
                      nav
                      onClick={(e) => e.preventDefault()}
                      role="button"
                    >
                      <i className="fa fa-user-circle-o" style={{ fontSize: "16px", marginRight: "8px" }}></i> {accountData?.firstName}
                    </DropdownToggle>
                  </Button>
                  <DropdownMenu
                    aria-labelledby="dropdownMenuButton"
                    className="dropdown-info"
                  >
                    {(userRole == "Admin" || userRole == "Eğitmen") ?
                      <DropdownItem
                        tag={Link}
                        to="/register#register"
                      //href="/user-blogs"
                      //onClick={(e) => e.preventDefault()}
                      >
                        Yeni Kayıt
                      </DropdownItem> : ""
                    }
                    {(userRole == "Admin") ?
                      <DropdownItem
                        tag={Link}
                        to="/change-password"
                      //href="/user-blogs"
                      //onClick={(e) => e.preventDefault()}
                      >
                        Şifre Değiştir
                      </DropdownItem> : ""
                    }

                    {(userRole == "Admin" || userRole == "Eğitmen") ?
                      <DropdownItem
                        tag={Link}
                        to="/courses"
                      //href="/user-blogs"
                      //onClick={(e) => e.preventDefault()}
                      >
                        Kurslar
                      </DropdownItem> : ""
                    }

                    {(userRole == "Admin" || userRole == "Eğitmen") ?
                      <DropdownItem
                        tag={Link}
                        to="/user-blogs"
                      //href="/user-blogs"
                      //onClick={(e) => e.preventDefault()}
                      >
                        Bloglarım
                      </DropdownItem> : ""
                    }

                    <DropdownItem
                      tag={Link}
                      to="/videos-page#videos-page"
                    >
                      Videolarım
                    </DropdownItem>

                    {(userRole == "Admin" || userRole == "Eğitmen") ?
                      <DropdownItem
                        tag={Link}
                        to="/videos-page#videos-page"
                      // href="/user-blogs"
                      // onClick={(e) => e.preventDefault()}
                      >
                        Öğrenci Videoları
                      </DropdownItem> : ""
                    }

                    {(userRole == "Admin" || userRole == "Eğitmen") ?
                      <DropdownItem
                        tag={Link}
                        to="/photos-page"
                      // href="/user-blogs"
                      // onClick={(e) => e.preventDefault()}
                      >
                        Fotoğraflar
                      </DropdownItem> : ""
                    }

                    <DropdownItem
                      href="#pablo"
                      onClick={userLogout}
                    >
                      Çıkış Yap
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div >
  );
}

export default CustomNavbar;