import React from "react";

import Layout from "./Layout.js";
import {
  Container,
} from "reactstrap";
import Gallery from "react-photo-gallery";
import api from "../api/api.js";

const PhotoGalleryPage = () => {
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    api.get(`Photo`).then((res) => {
      let photos = res.data.map(x => {
        return {
          src: x.url,
          width: 4,
          height: 3,
        }
      });
      setItems(photos);
    });
  }, []);

  return (
    <>
      <div className="main">
        <Layout pages={
          [
            <div
              className="section section-gallery" id="section-gallery"
            >
              <Container>
                <div className="content-center" style={{ margin: "50px" }}>
                  <h3 className="presentation-subtitle text-center" style={{ color: "#ee8c1c" }}>Galeri</h3>
                </div>

                <Gallery photos={items} />;
              </Container>
            </div>
          ]
        }></Layout>
      </div>
    </>
  );
}

export default PhotoGalleryPage;