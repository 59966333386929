import { GET_VIDEOS, GET_VIDEO, CREATE_VIDEO, RESET_VIDEO_DATA, USER_VIDEOS, RESET_SELECTED_VIDEO_DATA, CREATE_VIDEO_COMMENT, LOADING } from '../types';

const initialState = {
  data: {
    errors: null,
    message: null,
    result: null,
    succeeded: false,
  },
  selectedVideo: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VIDEOS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    }
    case GET_VIDEO: {
      return {
        ...state,
        selectedVideo: action.payload,
        loading: false,
      }
    }
    case CREATE_VIDEO: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    }
    case RESET_VIDEO_DATA: {
      return {
        ...state,
        data: action.payload,
      }
    }
    case RESET_SELECTED_VIDEO_DATA: {
      return {
        ...state,
        selectedVideo: null,
      }
    }
    case CREATE_VIDEO_COMMENT: {
      return {
        ...state,
      }
    }
    case USER_VIDEOS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    }
    case LOADING: {
      return {
        loading: true,
      }
    }

    default: return state;
  }
}