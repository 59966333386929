import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { HashLink } from 'react-router-hash-link';
import Layout from "../../Layout.js";
import CardVideo from "../../../components/CardVideo.js";
import api from "../../../api/api.js";

const VideosPage = () => {
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    api.get(`Video`).then((res) => {
      setItems(res.data.result)
      console.log(items)
    });
  }, []);

  return (
    <>
      <div className="main">
        <Layout pages={
          [
            <div
              className="section section-blogs" id="upload-video" key="upload-video"
            >
              <Container>
                <h2 className="text-center" style={{ marginBottom: "50px" }}>Videolar</h2>

                <HashLink smooth to="/upload-video#upload-video" style={{ color: "#FFF", fontWeight: 600 }}>
                  <Button className="btn-link mr-1 btn btn-warning" style={{ color: "#ee8c1c", marginBottom: "25px" }}>
                    <i className="fa fa-plus"></i> Yeni Video Yükle
                  </Button>
                </HashLink>

                {items.length > 0 &&
                  <Row>
                    {
                      items.map((x) =>
                        (x) ?
                          <Col className="mx-auto" lg="4" md="6" key={`c1`}>
                            <CardVideo
                              embedId={x.videoCode}
                              id={x.id}
                              title={x.title}
                              description={x.description}
                              publisher={x.publisher}
                            />
                          </Col> : "")
                    }
                  </Row>
                }

              </Container>
            </div>
          ]
        }></Layout>
      </div>

    </>
  );
}

export default VideosPage;