import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import Homepage from './views/Index.js';
import BlogsPage from './views/BlogsPage.js';
import BlogPage from './views/BlogPage.js';
import CoursesPage from './views/CoursesPage.js';
import PhotoGalleryPage from './views/PhotoGalleryPage.js';

import CreateBlogPage from './views/user-views/blog/CreateBlogPage.js';
import UserBlogs from './views/user-views/blog/UserBlogs.js';
import CoursePage from './views/CoursePage.js';

import CreateCoursePage from './views/user-views/course/CreateCoursePage.js';
import UserCourse from './views/user-views/course/UserCourse.js';

import UploadVideoPage from './views/user-views/video/UploadVideoPage.js';
import VideosPage from './views/user-views/video/VideosPage.js';
import VideoPage from './views/user-views/video/VideoPage.js';

import PhotosPage from './views/user-views/photos/PhotosPage.js';
import PhotoPage from './views/user-views/photos/PhotoPage.js';
import NewPhotoPage from './views/user-views/photos/NewPhotoPage.js';

import LoginPage from './views/LoginPage.js';
import RegisterPage from './views/RegisterPage.js';
import ChangePasswordPage from './views/ChangePasswordPage.js';

import ProfilePage from './views/user-views/user/ProfilePage';
import { isTokenValid } from './store/actions/loginActions.js';

import { role } from './helpers/userInformation.js';

const App = () => {
  const accountData = useSelector(state => state.account);
  const dispatch = useDispatch();
  const userRole = role();

  React.useEffect(() => {
    dispatch(isTokenValid());
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Homepage />} />
          <Route path="/sign-in" element={<LoginPage />} />
          <Route path="/blogs-page" element={<BlogsPage />} />
          <Route path="/courses-page" element={<CoursesPage />} />
          <Route path="/photo-gallery-page" element={<PhotoGalleryPage />} />

          <Route path="/blog/:id" element={<BlogPage />} />
          <Route path="/course/:id" element={<CoursePage />} />

          <Route path="/profile" element={accountData.isAuthenticated ? <ProfilePage /> : <Homepage />} />

          {/* register */}
          <Route path="/register" element={accountData.isAuthenticated && (userRole == "Admin" || userRole == "Eğitmen") ? <RegisterPage /> : <Homepage />} />
          <Route path="/change-password" element={accountData.isAuthenticated && userRole == "Admin" ? <ChangePasswordPage /> : <Homepage />} />

          {/* courses */}
          <Route path="/courses" element={accountData.isAuthenticated && (userRole == "Admin" || userRole == "Eğitmen") ? <UserCourse /> : <Homepage />} />
          <Route path="/create-course" element={accountData.isAuthenticated && (userRole == "Admin" || userRole == "Eğitmen") ? <CreateCoursePage /> : <Homepage />} />

          {/* blogs */}
          <Route path="/user-blogs" element={accountData.isAuthenticated && (userRole == "Admin" || userRole == "Eğitmen") ? <UserBlogs /> : <Homepage />} />
          <Route path="/create-blog" element={accountData.isAuthenticated && (userRole == "Admin" || userRole == "Eğitmen") ? <CreateBlogPage /> : <Homepage />} />

          {/* video */}
          <Route path="/upload-video" element={accountData.isAuthenticated ? <UploadVideoPage /> : <Homepage />} />
          <Route path="/videos-page" element={accountData.isAuthenticated ? <VideosPage /> : <Homepage />} />
          <Route path="/video-page/:id" element={accountData.isAuthenticated ? <VideoPage /> : <Homepage />} />

          <Route path="/photos-page" element={accountData.isAuthenticated ? <PhotosPage /> : <Homepage />} />
          <Route path="/photo-page/:id" element={accountData.isAuthenticated ? <PhotoPage /> : <Homepage />} />
          <Route path="/create-photo" element={accountData.isAuthenticated && userRole == "Admin" ? <NewPhotoPage /> : <Homepage />} />

          {/* accountData.isAuthenticated === false ? */}
          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
