import React from "react";
//sections
import Layout from "./Layout.js";

const SectionCourses = React.lazy(() => import('./index-sections/SectionCourses.js'));
const SectionBlogs = React.lazy(() => import('./index-sections/SectionBlogs.js'));
const SectionPhotoGallery = React.lazy(() => import('./index-sections/SectionPhotoGallery.js'));
const SectionInformation = React.lazy(() => import('./index-sections/SectionInformation.js'));

const Homepage = () => {
  return (
    <>
      <div className="main">
        <Layout pages={
          [
            <SectionInformation key="1" />,
            <SectionCourses key="2" />,
            <SectionPhotoGallery key="3" />,
            <SectionBlogs key="4" />,
          ]
        }></Layout>
      </div>
    </>
  );
}

export default Homepage;