import React from 'react';

import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import api from '../api/api';
import Layout from './Layout';

const CreateBlogPage = () => {
  const [users, setUsers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    api.get(`User`).then((res) => {
      setUsers(res.data.result);
    });
  }, []);


  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      userId: "",
      newPassword: "",
    }
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    api.put(`Account/${data.userId}/change-password?newPassword=${data.newPassword}`).then((res) => {
      navigate('/');
      setIsLoading(false);
    });
    setIsLoading(false);
  };

  return (
    <>
      <Layout pages={
        [
          <div className="section section-courses content-center" id="register" key="register">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="text-center">Yeni Kayıt</h2>
                  <form className="blog-form" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md="12">
                        <label>Kullanıcı</label>
                        <InputGroup>
                          <Controller
                            name="userId"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) =>
                              <Input
                                id="userId"
                                name="userid"
                                type="select"
                                autoComplete="off"
                                {...field}
                              >
                                <option disabled selected></option>
                                {users.map(x => <option value={x.id}>{x.fullName}</option>)}
                              </Input>
                            }
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label>Yeni Şifre</label>
                        <InputGroup>
                          <Controller
                            name="newPassword"
                            control={control}
                            rules={{
                              required: true,
                              minLength: 5
                            }}
                            render={({ field }) => <Input
                              placeholder="Şifre"
                              type="password"
                              name="newPassword"
                              autoComplete="off"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
<br /><br />
                    <Row>
                      {isLoading ?
                        <Col className="ml-auto mr-auto" md="2">
                          <ReactLoading type="spin" color="#f5593d" height={'60%'} width={'60%'} />
                        </Col>
                        :
                        <Col className="ml-auto mr-auto" md="4">

                          <Button type="submit" className="btn-fill" color="danger" size="lg" style={{ width: "100%" }}>
                            Kaydet
                          </Button>

                        </Col>
                      }
                    </Row>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        ]
      }></Layout>

    </>
  );
}

export default CreateBlogPage;