import { GET_BLOGS, GET_BLOG, CREATE_BLOG, DELETE_BLOG, RESET_BLOG_DATA, USER_BLOGS, LOADING } from '../types.js';
import api from '../../api/api.js';

export const resetBlogData = () => async dispatch => {
  let tmpData = {
    errors: null,
    message: null,
    result: null,
    succeeded: false,
  };

  dispatch({
    type: RESET_BLOG_DATA,
    payload: tmpData
  });
}

export const getBlogs = (pageNumber = null, pageSize = null) => async dispatch => {
  try {
    let res;
    if (pageNumber && pageSize)
      res = await api.get(`Blog?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    else
      res = await api.get(`Blog`);
    
    dispatch({
      type: GET_BLOGS,
      payload: res.data.result
    });
  }
  catch (e) {
    // dispatch({
    //   type: GET_BLOGS,
    //   payload: e.response.data,
    // })
  }
}

export const getBlog = (id) => async dispatch => {
  try {
    const res = await api.get(`Blog/${id}`);
    dispatch({
      type: GET_BLOG,
      payload: res.data
    });
  }
  catch (e) {
    // dispatch({
    //   type: GET_BLOG,
    //   payload: e.response.data,
    // })
  }
}

export const createBlog = (title, body, Image) => async dispatch => {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const res = await api.post(`Blog`, { title, body, Image, ImageURL: null, ImagePublicID: null }, config);
    dispatch({
      type: CREATE_BLOG,
      payload: res.data
    });
  }
  catch (e) {
    // dispatch({
    //   type: CREATE_BLOG,
    //   payload: e.response.data,
    // })
  }
}

export const deleteBlog = (id) => async dispatch => {
  try {
    const res = await api.delete(`Blog/${id}`);
    dispatch({
      type: DELETE_BLOG,
      payload: res.data.succeeded
    });
  }
  catch (e) {
    // dispatch({
    //   type: DELETE_BLOG,
    //   payload: e.response.data,
    // })
  }
}

export const userBlogs = (pageNumber, pageSize) => async dispatch => {
  try {
    const res = await api.get(`User/blogs`);
    dispatch({
      type: USER_BLOGS,
      payload: res.data.result
    });
  }
  catch (e) {
    // dispatch({
    //   type: USER_BLOGS,
    //   payload: e.response.data,
    // })
  }
}

export const loading = () => async dispatch => {
  dispatch({
    type: LOADING,
    payload: true
  });
}