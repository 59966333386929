import React from "react";
import Vimeo from '@u-wave/react-vimeo';

const VideoEmbed = ({ embedId, videoWidth, videoHeight }) => (
  <div className="video-responsive" style={{ backgroundColor: '#000', textAlign: 'center' }}>
    <Vimeo
      video={embedId}
      //width={videoWidth}
      height={videoHeight}
      showTitle={false}
      showPortrait={false}
      showByline={false}
      pip={false}
      controls={false}
    />
  </div>
);

export default VideoEmbed;