import { IS_TOKEN_VALID, LOGIN, LOGIN_ERROR, LOGOUT, REGISTER } from '../types.js';
import api from '../../api/api.js';
import { setAuthorizationToken } from '../../helpers/setAuthorizationToken.js';
import { userInformation } from "../../helpers/userInformation.js";
import moment from 'moment';

export const login = (loginInformation) => async dispatch => {
  try {
    const res = await api.post(`Account/login`, { ...loginInformation });
    console.log('res.data');
    console.log(res.data);
    const { result } = res.data;
    localStorage.setItem('firstName', result.firstName);
    localStorage.setItem('lastName', result.lastName);
    localStorage.setItem('username', result.username);
    localStorage.setItem('jwtToken', result.jwtToken);
    localStorage.setItem('refreshToken', result.refreshToken);

    setAuthorizationToken(result.jwtToken);

    dispatch({
      type: LOGIN,
      payload: res.data
    });
  }
  catch (e) {
    dispatch({
      type: LOGIN_ERROR,
      payload: e.response.data,
    })
  }
}

export const logout = () => async dispatch => {
  try {
    const token = localStorage.getItem('jwtToken');
    localStorage.clear();
    setAuthorizationToken('');
    const res = await api.delete(`Account/revokeToken`, { token });

    dispatch({
      type: LOGOUT,
      payload: res.data
    });
  }
  catch (e) {
    dispatch({
      type: LOGOUT,
      payload: e.response.data,
    })
  }
}

export const register = (data) => async dispatch => {
  try {
    const res = await api.post(`Account/register`, { ...data });
    dispatch({
      type: REGISTER,
      payload: res.data
    });
  }
  catch (e) {
    dispatch({
      type: REGISTER,
      payload: e.response.data,
    })
  }
}

export const isTokenValid = () => dispatch => {
  const res = userInformation();
  let isAuthenticated = false, firstName = "", lastName = "";

  if (res) {
    isAuthenticated = !moment().isAfter(new Date(res.token.exp * 1000));
    firstName = res.firstName;
    lastName = res.lastName;
  }

  dispatch({
    type: IS_TOKEN_VALID,
    payload: { isAuthenticated, firstName, lastName },
  });
}