import React from "react";

import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from "reactstrap";
import { HashLink } from "react-router-hash-link";
import VideoEmbed from "./YoutubeEmbed";
// import { Player } from 'video-react';
// import "../../node_modules/video-react/dist/video-react.css";

const CardVideo = (props) => {
  return (
    <div>
      <Card style={{ backgroundColor: "#F7F7F7" }}>
        <VideoEmbed embedId={props.embedId} videoWidth="100%" videoHeight="250px" />
        <CardBody>
          <CardTitle><h5>{props.publisher}</h5></CardTitle>
          <CardTitle><h5>{props.title}</h5></CardTitle>
          <CardText style={{ minHeight: "100px" }}>{props.description}</CardText>
          <HashLink smooth to={`/video-page/${props.id}`} style={{ color: "#FFF", fontWeight: 600 }}>
            <Button className="btn-link mr-1 btn btn-warning" style={{ color: "#ee8c1c", position: "absolute", bottom: "20px" }}>Videoyu Gör <i className="fa fa-arrow-right"></i></Button>
          </HashLink>
        </CardBody>
      </Card>
    </div>
  )
}

export default CardVideo;