import React from "react";

import CustomNavbar from "../components/Navbars/CustomNavbar.js";
import IndexHeader from "../components/Headers/IndexHeader.js";
import DemoFooter from "../components/Footers/DemoFooter.js";
import { enumPages } from "../constants/pageTypes.js";

const Layout = (props) => {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("index");
        return function cleanup() {
            document.body.classList.remove("index");
        };
    });

    return (
        <>
            <CustomNavbar type={enumPages.SIGNED_OUT} />
            <IndexHeader />
            <div className="main">
                {props.pages}
            </div>
            <DemoFooter />
        </>
    );
}

export default Layout;