import React from "react";
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
//sections
import Layout from "./Layout.js";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { getBlog, deleteBlog, resetBlogData } from '../store/actions/blogActions';
import { role } from '../helpers/userInformation.js';

const BlogPage = () => {
  const dispatch = useDispatch();
  const blogData = useSelector(state => state.blog);
  const { id } = useParams();
  const navigate = useNavigate();
  const userRole = role();

  React.useEffect(() => {
    dispatch(getBlog(id));
  }, []);

  React.useLayoutEffect(() => {
    return () => {
      dispatch(resetBlogData());
    }
  }, [])

  React.useEffect(() => {
    if (blogData.isBlogDeleted) {
      confirmAlert({
        message: 'Blog silindi.',
        buttons: [
          {
            label: 'Tamam',
            onClick: () => navigate('/#section-blog')
          },
        ]
      });
    }
    else if (blogData.isBlogDeleted == false) {
      confirmAlert({
        message: 'Bir sorun oluştu.',
        buttons: [
          {
            label: 'Tamam',
          },
        ]
      });
    }
  }, [blogData.isBlogDeleted]);

  const removeBlog = () => {
    confirmAlert({
      //title: 'Uyarı',
      message: 'Blog silinecektir. Onaylıyor musunuz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => dispatch(deleteBlog(id))
        },
        {
          label: 'Vazgeç',

        }
      ]
    });
  }

  return (
    <>
      <div className="main">
        <Layout pages={
          [
            <div className="section profile-content" id="blog-page">
              <Container>
                <br />
                <br />
                <div className="owner">
                  <Row>
                    <Col md="8" className="ml-auto mr-auto">
                      <div className="img">
                        <img src={blogData.data?.result?.imageURL} style={{ maxWidth: "800px", width: "100%" }}></img>
                      </div>
                    </Col>
                  </Row>

                  <br />
                  <br />
                  <div className="name">
                    <h4 className="title">
                      {blogData.data?.result?.title}
                      <br />
                    </h4>
                    <div style={{ lineBreak: "anywhere", textAlign: "left" }} dangerouslySetInnerHTML={{ __html: blogData.data?.result?.body }} />
                  </div>
                </div>

                {(userRole == "Admin") ?
                  <Row>
                    <Col className="mx-auto" style={{ textAlign: 'center' }} lg="4" md="6">
                      <Button className="btn-link btn btn-warning" style={{ color: "red", bottom: "20px" }} onClick={removeBlog}>Blogu Sil</Button>
                    </Col>
                  </Row> : null
                }

              </Container>
            </div>
          ]
        }></Layout>
      </div>
    </>
  );
}

export default BlogPage;