import React from 'react';
import {
  Button,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useForm, Controller } from 'react-hook-form';
import Layout from '../../Layout';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';

const UploadVideoPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      file: null,
    }
  });

  const onSubmit = (data, e) => {
    setIsLoading(true);
    data.file = e.target.file.files[0];
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    api.post(`Photo`, { file: data.file }, config).then((res) => {
      navigate('/photos-page');
    });
    //dispatch(createVideo(data.title, data.description, data.Video));
  };
  return (
    <>
      <Layout pages={
        [
          <div className="section section-courses content-center" id="create-blog" key="create-blog">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="text-center">Video Yükle</h2>
                  <form className="blog-form" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md="12">
                        <label>Video</label>
                        <InputGroup>
                          <Controller
                            name="file"
                            control={control}
                            render={({ field }) => <Input
                              type="file"
                              name="file"
                              accept="image/*"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "20px" }}>
                      {isLoading ?
                        <Col className="ml-auto mr-auto" md="2">
                          <ReactLoading type="spin" color="#f5593d" height={'60%'} width={'60%'} />
                        </Col>
                        :
                        <Col className="ml-auto mr-auto" md="4">
                          <Button type="submit" className="btn-fill" color="danger" size="lg" style={{ width: "100%" }}>
                            Kaydet
                          </Button>
                        </Col>
                      }
                    </Row>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        ]
      }></Layout>

    </>
  )
}

export default UploadVideoPage;