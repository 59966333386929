import React from "react";
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
//sections
import Layout from "./Layout.js";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { getCourse, deleteCourse } from '../store/actions/courseActions';
import { role } from '../helpers/userInformation.js';

const CoursePage = () => {
  const dispatch = useDispatch();
  const courseData = useSelector(state => state.course);
  const { id } = useParams();
  const navigate = useNavigate();
  const userRole = role();

  React.useEffect(() => {
    dispatch(getCourse(id));
  }, []);

  React.useEffect(() => {
    if (courseData.isCourseDeleted) {
      confirmAlert({
        message: 'Kurs silindi.',
        buttons: [
          {
            label: 'Tamam',
            onClick: () => navigate('/#section-course')
          },
        ]
      });
    }
    else if (courseData.isCourseDeleted == false) {
      confirmAlert({
        message: 'Bir sorun oluştu.',
        buttons: [
          {
            label: 'Tamam',
          },
        ]
      });
    }
  }, [courseData.isCourseDeleted]);

  const removeCourse = () => {
    confirmAlert({
      //title: 'Uyarı',
      message: 'Kurs silinecektir. Onaylıyor musunuz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => dispatch(deleteCourse(id))
        },
        {
          label: 'Vazgeç',

        }
      ]
    });
  }

  return (
    <>
      <div className="main">
        <Layout pages={
          [
            <div className="section profile-content" id="blog-page">
              <Container>
                <br />
                <br />
                <div className="owner">
                  <Row>
                    <Col md="8" className="ml-auto mr-auto">
                      <div className="img">
                        <img src={courseData.data?.result?.imageURL} style={{ maxWidth: "800px", width: "100%" }}></img>
                      </div>
                    </Col>
                  </Row>

                  <br />
                  <br />
                  <div className="name">
                    <h4 className="title">
                      {courseData.data?.result?.title}
                      <br />
                    </h4>
                    <div style={{ lineBreak: "anywhere", textAlign: "left" }} dangerouslySetInnerHTML={{ __html: courseData.data?.result?.body }} />
                  </div>
                </div>

                {(userRole == "Admin") ?
                  <Row>
                    <Col className="mx-auto" style={{ textAlign: 'center' }} lg="4" md="6">
                      <Button className="btn-link btn btn-warning" style={{ color: "red", bottom: "20px" }} onClick={removeCourse}>Kursu Sil</Button>
                    </Col>
                  </Row> : null
                }

              </Container>
            </div>
          ]
        }></Layout>
      </div>
    </>
  );
}

export default CoursePage;