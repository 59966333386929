export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REGISTER = 'REGISTER';

export const IS_TOKEN_VALID = 'IS_TOKEN_VALID';

export const USER_INFORMATION = 'USER_INFORMATION';

export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOG = 'GET_BLOG';
export const CREATE_BLOG = 'CREATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';
export const RESET_BLOG_DATA = 'RESET_BLOG_DATA';
export const USER_BLOGS = 'USER_BLOGS';

export const GET_COURSES = 'GET_BLOGS';
export const GET_COURSE = 'GET_BLOG';
export const CREATE_COURSE = 'CREATE_BLOG';
export const DELETE_COURSE = 'DELETE_BLOG';
export const RESET_COURSE_DATA = 'RESET_BLOG_DATA';
export const USER_COURSES = 'USER_BLOGS';

export const GET_VIDEOS = 'GET_VIDEOS';
export const GET_VIDEO = 'GET_VIDEO';
export const CREATE_VIDEO = 'CREATE_VIDEO';
export const RESET_VIDEO_DATA = 'RESET_VIDEO_DATA';
export const USER_VIDEOS = 'USER_VIDEOS';
export const CREATE_VIDEO_COMMENT = 'CREATE_VIDEO_COMMENT';
export const RESET_SELECTED_VIDEO_DATA = 'RESET_SELECTED_VIDEO_DATA';

export const LOADING = 'LOADING';
