import React from "react";
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
//sections
import Layout from "../../Layout.js";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { role } from '../../../helpers/userInformation.js';
import api from "../../../api/api.js";

const PhotoPage = () => {
  const [image, setImage] = React.useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const userRole = role();

  React.useEffect(() => {
    api.get(`Photo`).then((res) => {
      setImage(res.data.filter(x => x.id == id)[0])
    });
  }, []);

  const removePhoto = () => {
    api.delete(`Photo/${image.publicId.replace('/', '%2F')}`).then((res) => {
      navigate('/photos-page');
    });
  }

  const removePhotoAlert = () => {
    confirmAlert({
      //title: 'Uyarı',
      message: 'Fotoğraf silinecektir. Onaylıyor musunuz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => removePhoto(id)
        },
        {
          label: 'Vazgeç',

        }
      ]
    });
  }

  return (
    <>
      <div className="main">
        <Layout pages={
          [
            <div className="section profile-content" id="blog-page">
              <Container>
                <br />
                <br />
                <div className="owner">
                  <Row>
                    <Col md="8" className="ml-auto mr-auto">
                      <div className="img">
                        <img src={image.url} style={{ maxWidth: "800px" }}></img>
                      </div>
                    </Col>
                  </Row>
                </div>

                {(userRole == "Admin") ?
                  <Row>
                    <Col className="mx-auto" style={{ textAlign: 'center' }} lg="4" md="6">
                      <Button className="btn-link btn btn-warning" style={{ color: "red", bottom: "20px" }} onClick={removePhotoAlert}>Fotoğrafı Sil</Button>
                    </Col>
                  </Row> : null
                }

              </Container>
            </div>
          ]
        }></Layout>
      </div>
    </>
  );
}

export default PhotoPage;