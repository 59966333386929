import { GET_COURSES, GET_COURSE, CREATE_COURSE, DELETE_COURSE, RESET_COURSE_DATA, USER_COURSES, LOADING } from '../types.js';
import api from '../../api/api.js';

export const resetCourseData = () => async dispatch => {
  let tmpData = {
    errors: null,
    message: null,
    result: null,
    succeeded: false,
  };

  dispatch({
    type: RESET_COURSE_DATA,
    payload: tmpData
  });
}

export const getCourses = (pageNumber = null, pageSize = null) => async dispatch => {
  try {
    let res;
    if (pageNumber && pageSize)
      res = await api.get(`Course?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    else
      res = await api.get(`Course`);
    console.log('Course', res);
    dispatch({
      type: GET_COURSES,
      payload: res.data.result
    });
  }
  catch (e) {

  }
}

export const getCourse = (id) => async dispatch => {
  try {
    const res = await api.get(`Course/${id}`);
    dispatch({
      type: GET_COURSE,
      payload: res.data
    });
  }
  catch (e) {

  }
}

export const createCourse = (title, body, Image) => async dispatch => {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const res = await api.post(`Course`, { title, body, Image, ImageURL: null, ImagePublicID: null }, config);
    dispatch({
      type: CREATE_COURSE,
      payload: res.data
    });
  }
  catch (e) {

  }
}

export const deleteCourse = (id) => async dispatch => {
  try {
    const res = await api.delete(`Course/${id}`);
    dispatch({
      type: DELETE_COURSE,
      payload: res.data.succeeded
    });
  }
  catch (e) {

  }
}

export const loading = () => async dispatch => {
  dispatch({
    type: LOADING,
    payload: true
  });
}