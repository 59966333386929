import jwtDecode from "jwt-decode";

export const userInformation = () => {
  if (localStorage.getItem("jwtToken") !== null) {
    const token = localStorage.getItem("jwtToken");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");

    if (token) {
      return { token: jwtDecode(token), firstName, lastName };
    }
  }

  return null;
}

export const role = () => {
  if (localStorage.getItem("jwtToken") !== null) {
    const token = jwtDecode(localStorage?.getItem("jwtToken"));

    if (token) {
      return token.role;
    }
  }

  return "";
}