import { combineReducers } from '@reduxjs/toolkit';
import accountReducer from './accountReducer';
import blogReducer from './blogReducer';
import userReducer from './userReducer';
import videoReducer from './videoReducer';
import courseReducer from './courseReducer';

export default combineReducers({
  account: accountReducer,
  blog: blogReducer,
  user: userReducer,
  video: videoReducer,
  course: courseReducer,
});