import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getBlogs } from '../store/actions/blogActions';
//sections
import Layout from "./Layout.js";
//Components 
import CardBlog from "../components/CardBlog.js";


const BlogsPage = () => {
  const dispatch = useDispatch();
  const blogData = useSelector(state => state.blog);

  React.useEffect(() => {
    dispatch(getBlogs());
  }, []);

  return (
    <>
      <div className="main">
        <Layout pages={
          [
            <div
              className="section section-blogs" id="section-blogs" key="section-blogs"
            >
              <Container>
                <div className="content-center" style={{ margin: "50px" }}>
                  <h3 className="presentation-subtitle text-center" style={{ color: "#ee8c1c" }}>Bloglar</h3>
                </div>

                {blogData.blogs.length > 0 &&
                  <Row>
                    {
                      blogData?.blogs?.map((x) => <Col className="mx-auto" lg="4" md="6" key={`c${x?.id}`}>
                        <CardBlog src={x?.imageURL} id={x?.id} title={x?.title} description={x?.body} isBlog={true} />
                      </Col>)
                    }
                  </Row>
                }
              </Container>
            </div>
          ]
        }></Layout>
      </div>
    </>
  );
}

export default BlogsPage;