import { LOGIN, LOGOUT, LOGIN_ERROR, IS_TOKEN_VALID, REGISTER } from '../types';

const initialState = {
    data: {
        errors: null,
        message: "",
        result: null,
        succeeded: false,
    },
    loading: true,
    isAuthenticated: false,
    firstName: "",
    lastName: "",
    role: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN: {
            return {
                ...state,
                data: action.payload,
                loading: false,
                isAuthenticated: true,
                firstName: action.payload.result.firstName,
                lastName: action.payload.result.lastName,
            }
        }
        case LOGOUT: {
            return {
                ...state,
                data: action.payload,
                loading: false,
                isAuthenticated: false,
                firstName: "",
                lastName: ""
            }
        }
        case LOGIN_ERROR: {
            return {
                ...state,
                data: action.payload,
                loading: false,
                isAuthenticated: false,
            }
        }
        case IS_TOKEN_VALID: {
            return {
                ...state,
                loading: false,
                isAuthenticated: action.payload.isAuthenticated,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
            }
        }
        case REGISTER: {
            return {
                ...state,
                data: action.payload,
                loading: false,
                isAuthenticated: false,
            }
        }

        default: return state;
    }
}