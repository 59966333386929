import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './store/store';
import reportWebVitals from './reportWebVitals';

import { setAuthorizationToken } from './helpers/setAuthorizationToken';

// styles
import "bootstrap/scss/bootstrap.scss";
import "./assets/scss/paper-kit.scss?v=1.3.0";
import "./assets/demo/demo.css?v=1.3.0";
//pages
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

const jwtToken = localStorage.getItem("jwtToken");
if (jwtToken) {
  setAuthorizationToken(jwtToken);
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
