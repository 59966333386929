import React from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { register } from '../store/actions/loginActions';

import Layout from './Layout';

const CreateBlogPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountData = useSelector(state => state.account);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      role: ""
    }
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    dispatch(register(data));
    setIsLoading(false);
  };

  return (
    <>
      <Layout pages={
        [
          <div className="section section-courses content-center" id="register" key="register">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="text-center">Yeni Kayıt</h2>
                  <form className="blog-form" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md="12">
                        <label>E-mail</label>
                        <InputGroup>
                          <Controller
                            name="email"
                            control={control}
                            rules={{
                              required: true,
                              minLength: 5
                            }}
                            render={({ field }) => <Input
                              placeholder="E-mail"
                              type="text"
                              name="email"
                              autoComplete="off"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label>Şifre</label>
                        <InputGroup>
                          <Controller
                            name="password"
                            control={control}
                            rules={{
                              required: true,
                              minLength: 5
                            }}
                            render={({ field }) => <Input
                              placeholder="Şifre"
                              type="password"
                              name="password"
                              autoComplete="off"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label>Ad</label>
                        <InputGroup>
                          <Controller
                            name="firstName"
                            control={control}
                            rules={{
                              required: true,
                              minLength: 1
                            }}
                            render={({ field }) => <Input
                              placeholder="Ad"
                              type="text"
                              name="firstName"
                              autoComplete="off"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label>Soyad</label>
                        <InputGroup>
                          <Controller
                            name="lastName"
                            control={control}
                            rules={{
                              required: true,
                              minLength: 1
                            }}
                            render={({ field }) => <Input
                              placeholder="Soyad"
                              type="text"
                              name="lastName"
                              autoComplete="off"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>

                    <Controller
                      name="role"
                      control={control}
                      render={({ field }) => <FormGroup tag="fieldset" {...field}>
                        <legend>Hesap Türü</legend>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="role" value="Admin" /> Admin
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="role" value="Eğitmen" /> Eğitmen
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="role" value="Öğrenci" /> Öğrenci
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      }
                    />
                    <Row>
                      {isLoading ?
                        <Col className="ml-auto mr-auto" md="2">
                          <ReactLoading type="spin" color="#f5593d" height={'60%'} width={'60%'} />
                        </Col>
                        :
                        <Col className="ml-auto mr-auto" md="4">

                          <Button type="submit" className="btn-fill" color="danger" size="lg" style={{ width: "100%" }}>
                            Kaydet
                          </Button>

                        </Col>
                      }
                    </Row>

                    {/* <Row style={{ marginTop: "20px" }}>
                                            <Col className="ml-auto mr-auto" md="4">
                                                <Button type="submit" className="btn-fill" color="danger" size="lg" style={{ width: "100%" }}>
                                                    Kaydet
                                                </Button>
                                            </Col>
                                        </Row> */}
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        ]
      }></Layout>

    </>
  );
}

export default CreateBlogPage;