import { GET_COURSES, GET_COURSE, CREATE_COURSE, DELETE_COURSE, RESET_COURSE_DATA, USER_COURSES, LOADING } from '../types.js';

const initialState = {
  data: {
    errors: null,
    message: null,
    result: null,
    succeeded: false
  },
  course: null,
  courses: [],
  loading: true,
  isCourseCreated: false,
  userCourses: [],
  isCourseDeleted: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COURSES: {
      return {
        ...state,
        //data: action.payload,
        courses: action.payload,
        loading: false,
      }
    }
    case GET_COURSE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    }
    case CREATE_COURSE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        isCourseCreated: action.payload.succeeded,
      }
    }
    case DELETE_COURSE: {
      return {
        ...state,
        //data: action.payload,
        isCourseDeleted: action.payload,
        loading: false,
      }
    }
    case RESET_COURSE_DATA: {
      return {
        ...state,
        data: action.payload,
        isCourseCreated: false,
        courses: [],
        isCourseDeleted: null
      }
    }

    case USER_COURSES: {
      return {
        ...state,
        data: action.payload,
        userCourses: action.payload.result,
        loading: false,
      }
    }
    case LOADING: {
      return {
        loading: true,
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}