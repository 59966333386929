import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../../components/Navbars/CustomNavbar.js";
import ProfilePageHeader from "../../../components/Headers/ProfilePageHeader.js";
import profilePicture from "../../../assets/img/logo512.png";

const ProfilePage = () => {
  const [activeTab, setActiveTab] = React.useState("1");

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                style={{ backgroundColor: "transparent" }}
                src={profilePicture}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ProfilePage;