import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactLoading from 'react-loading';
import Layout from "../../Layout.js";
import VideoEmbed from "../../../components/YoutubeEmbed.js";
import { useDispatch, useSelector } from 'react-redux';
import { getVideo, createVideoComment } from '../../../store/actions/videoActions';
import { useForm, Controller } from 'react-hook-form';
import { role } from '../../../helpers/userInformation.js';
import api from "../../../api/api.js";
import { useNavigate } from 'react-router-dom';

const VideoPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [videoComments, setVideoComments] = React.useState([]);
  let { id } = useParams();
  const dispatch = useDispatch();
  const videoData = useSelector(state => state.video);
  const userRole = role();

  React.useEffect(() => {
    if (videoData.selectedVideo == null || videoData.selectedVideo.commentDto.length == 0)
      return;

    let tmpVideoComments = [];
    videoData.selectedVideo.commentDto.map(comment => {
      tmpVideoComments.push(<><label style={{ fontWeight: 500 }}>{comment.comment}</label><br /><hr /></>);
    });

    setVideoComments(tmpVideoComments);
  }, [videoData]);

  React.useEffect(() => {
    dispatch(getVideo(id));
  }, []);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      videoComment: "",
      id: id,
    }
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    dispatch(createVideoComment(data.id, data.videoComment));
    setIsLoading(false);
  };

  const removeVideo = () => {
    setIsLoading(true);
    api.delete(`Video/${id}`).then((res) => {
      navigate('/videos-page');
      setIsLoading(false);
    });
  }

  const removeVideoAlert = () => {
    confirmAlert({
      //title: 'Uyarı',
      message: 'Video silinecektir. Onaylıyor musunuz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => removeVideo(id)
        },
        {
          label: 'Vazgeç',

        }
      ]
    });
  }

  return (
    <>
      <Layout pages={
        [
          (videoData.selectedVideo == null) ?
            <Col className="ml-auto mr-auto" md="2">
              <ReactLoading type="spin" color="#f5593d" height={'60%'} width={'60%'} />
            </Col> :
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="9" style={{ marginBottom: "25px" }}>
                  <div className="App" id="videos-page">
                    <h2 className="text-center">Video - {videoData.selectedVideo.publisher}</h2>
                    <br />
                    <br />
                    <VideoEmbed embedId={videoData.selectedVideo.videoCode} videoWidth="100%" videoHeight="480px" />
                  </div>
                  <br />
                  <br />
                </Col>
                {(userRole != 'Öğrenci') ?
                  <Col className="ml-auto mr-auto" md="9">
                    <form className="blog-form" onSubmit={handleSubmit(onSubmit)}>
                      <Controller
                        name="videoComment"
                        control={control}
                        rules={{
                          required: true,
                          minLength: 1,
                        }}
                        render={({ field }) => <Input
                          placeholder="Yorum yaz..."
                          type="text"
                          name="videoComment"
                          autoComplete="off"
                          {...field}
                        />}
                      />
                      <br />
                      {isLoading ?
                        <Col className="ml-auto mr-auto" md="2">
                          <ReactLoading type="spin" color="#f5593d" height={'60%'} width={'60%'} />
                        </Col>
                        :
                        <Col className="ml-auto mr-auto" md="4">
                          <Button type="submit" className="btn-fill" color="danger" size="md" style={{ width: "100%" }}>
                            Kaydet
                          </Button>
                        </Col>
                      }
                    </form>
                  </Col>
                  : null
                }

                <Col className="ml-auto mr-auto" md="9">
                  <h2 className="text-center">Yorumlar</h2>
                  {videoComments}
                  <br />
                  <br />
                </Col>
                <Col className="ml-auto mr-auto" md="9">
                  {(userRole == "Admin") ?
                    <Row>
                      <Col className="mx-auto" style={{ textAlign: 'center' }} lg="4" md="6">
                        <Button className="btn-link btn btn-warning" style={{ color: "red", bottom: "20px" }} onClick={removeVideoAlert}>Videoyu Sil</Button>
                      </Col>
                    </Row> : null
                  }
                </Col>
              </Row>
            </Container>]
      }></Layout>
    </>
  );
}

export default VideoPage;