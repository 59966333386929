import React from 'react';
import {
  Button,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useForm, Controller } from 'react-hook-form';
import Layout from '../../Layout';
import { role } from '../../../helpers/userInformation';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';

const UploadVideoPage = () => {
  const [users, setUsers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      title: "",
      description: "",
      Video: null,
      userId: null
    }
  });

  React.useEffect(() => {
    api.get(`User`).then((res) => {
      setUsers(res.data.result);
    });
  }, []);

  const onSubmit = (data, e) => {
    setIsLoading(true);
    data.Video = e.target.file.files[0];

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    if (role() == "Admin") {
      api.post(`Video/${data.userId}`, { Title: data.title, Description: data.description, Video: data.Video, VideoCode: null }, config).then((res) => {
        navigate('/videos-page#videos-page');
        setIsLoading(false);
      });
    }
    else {
      api.post(`Video`, { Title: data.title, Description: data.description, Video: data.Video, VideoCode: null }, config).then((res) => {
        navigate('/videos-page#videos-page');
        setIsLoading(false);
      });
    }
  };

  return (
    <>
      <Layout pages={
        [
          <div className="section section-courses content-center" id="create-video" key="create-video">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="text-center">Video Yükle</h2>
                  <form className="blog-form" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      {(role() == "Admin") ?
                        <Col md="12">
                          <label>Kullanıcı</label>
                          <InputGroup>
                            <Controller
                              name="userId"
                              control={control}
                              rules={{
                                required: true,
                              }}
                              render={({ field }) =>
                                <Input
                                  id="userId"
                                  name="userid"
                                  type="select"
                                  autoComplete="off"
                                  {...field}
                                >
                                  <option disabled selected></option>
                                  {users.map(x => <option value={x.id}>{x.fullName}</option>)}
                                </Input>
                              }
                            />
                          </InputGroup>
                        </Col> : null}
                    </Row>
                    <Row>
                      <Col md="12">
                        <label>Video</label>
                        <InputGroup>
                          <Controller
                            name="file"
                            control={control}
                            render={({ field }) => <Input
                              type="file"
                              name="file"
                              accept="video/mp4,video/x-m4v,video/*"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label>Başlık</label>
                        <InputGroup>
                          <Controller
                            name="title"
                            control={control}
                            rules={{
                              required: true,
                              minLength: 1
                            }}
                            render={({ field }) => <Input
                              placeholder="Başlık"
                              type="text"
                              name="title"
                              autoComplete="off"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label>Açıklama</label>
                        <InputGroup>
                          <Controller
                            name="description"
                            control={control}
                            rules={{
                              required: true,
                              minLength: 1
                            }}
                            render={({ field }) => <Input
                              placeholder="Açıklama"
                              type="text"
                              name="description"
                              autoComplete="off"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "20px" }}>
                      {isLoading ?
                        <Col className="ml-auto mr-auto" md="2">
                          <ReactLoading type="spin" color="#f5593d" height={'60%'} width={'60%'} />
                        </Col>
                        :
                        <Col className="ml-auto mr-auto" md="4">
                          <Button type="submit" className="btn-fill" color="danger" size="lg" style={{ width: "100%" }}>
                            Kaydet
                          </Button>
                        </Col>
                      }
                    </Row>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        ]
      }></Layout>

    </>
  )
}

export default UploadVideoPage;