import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";
// images
import background from "../../assets/img/piano-background.jpg";
import fogLow from "../../assets/img/fog-low.png";
import clouds from "../../assets/img/clouds.png";

// core components
const IndexHeader = () => {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + background + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              {/* <h2 className="presentation-subtitle text-center">
                Punta Sanat Akademi
              </h2> */}
              <br />
              <h1 className="presentation-title">Punta Sanat Akademi</h1>
            </div>
          </Container>
        </div>

        <div
          className="moving-clouds"
          style={{
            backgroundImage:
              "url(" + clouds + ")",
          }}
        />

      </div>
    </>
  );
}

export default IndexHeader;
