import { USER_INFORMATION } from '../types';

const initialState = {
  data: {
    errors: null,
    message: "",
    result: null,
    succeeded: false,
  },
  loading: true,
  isAuthenticated: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_INFORMATION: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        isAuthenticated: true,
      }
    }
    default: return state;
  }
}