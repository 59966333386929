import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


// reactstrap components
import carousel1 from "../../assets/img/punta-sanat/carousel1.jpg";
import carousel2 from "../../assets/img/punta-sanat/carousel2.jpg";
import carousel3 from "../../assets/img/punta-sanat/carousel3.jpg";
import carousel4 from "../../assets/img/punta-sanat/carousel4.jpg";
// core components

function ProfilePageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      {/* <div
                style={{
                    backgroundImage:
                        "url(" + background + ")",
                }}
                className="page-header page-header-xs"
                data-parallax={true}
                ref={pageHeader}
            >
                <div className="filter" />
            </div> */}
      <Carousel>
        <div>
          <img src={carousel1} />
          <p className="legend">1</p>
        </div>
        <div>
          <img src={carousel2} />
          <p className="legend">2</p>
        </div>
        <div>
          <img src={carousel3} />
          <p className="legend">3</p>
        </div>
        <div>
          <img src={carousel4} />
          <p className="legend">4</p>
        </div>
      </Carousel>
    </>
  );
}

export default ProfilePageHeader;
