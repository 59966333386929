// import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
// import accountReducer from '../features/account/accountSlice';

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//     account: accountReducer,
//   },
// });
import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
//import { configureStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

const initalState = {

}

const middleware = [thunk]

export const store = configureStore({ reducer: rootReducer }, initalState, composeWithDevTools(applyMiddleware(...middleware)));
