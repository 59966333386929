import React from "react";

import { Row, Col, Container } from "reactstrap";

const DemoFooter = () => {
  return (
    <footer className="footer footer-black" style={{ color: "whitesmoke" }}>
      <Container>
        <Row>
          <Col xs="12" sm="12" md="4" style={{ marginBottom: "10px" }}>
            <Row>
              <Col>
                <h5>Bize Ulaşın</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  Punta Sanat
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <i className="fa fa-phone"></i>
                  <a href="tel:5073206347" style={{ color: "whitesmoke", fontWeight: 500 }}> 0507-320-6347</a>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <i className="fa fa-instagram"></i>
                  <a href="https://instagram.com/puntasanat" style={{ color: "whitesmoke", fontWeight: 500 }}> Punta Sanat</a>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <i className="fa fa-home"></i> Arap Hasan, 235. Sk. No:76 daire:1, 35280 Karabağlar/İzmir, Türkiye
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs="12" sm="12" md="8">
            <Row>
              <Col>
                <iframe style={{ width: "100%" }} id="gmap_canvas" src="https://maps.google.com/maps?q=punta%20sanat&t=&z=13&ie=UTF8&iwloc=&output=embed&z=19" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer >
  );
}

export default DemoFooter;
