import { GET_BLOGS, GET_BLOG, CREATE_BLOG, DELETE_BLOG, RESET_BLOG_DATA, USER_BLOGS, LOADING } from '../types';

const initialState = {
  data: {
    errors: null,
    message: null,
    result: null,
    succeeded: false
  },
  blogs: [],
  loading: true,
  isBlogCreated: false,
  userBlogs: [],
  isBlogDeleted: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BLOGS: {
      return {
        ...state,
        blogs: action.payload,
        loading: false,
      }
    }
    case GET_BLOG: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    }
    case CREATE_BLOG: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        isBlogCreated: action.payload.succeeded,
      }
    }
    case DELETE_BLOG: {
      return {
        ...state,
        //data: action.payload,
        isBlogDeleted: action.payload,
        loading: false,
      }
    }
    case RESET_BLOG_DATA: {
      return {
        ...state,
        data: action.payload,
        isBlogCreated: false,
        blogs: [],
        isBlogDeleted: null
      }
    }

    case USER_BLOGS: {
      return {
        ...state,
        userBlogs: action.payload,
        loading: false,
      }
    }

    case LOADING: {
      return {
        loading: true,
      }
    }

    default: {
      return {
        ...state
      }
    }
  }
}