import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
//sections
import Layout from "./Layout.js";
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from "../store/actions/courseActions.js";
import CardBlog from "../components/CardBlog.js";

const CoursesPage = () => {
  const dispatch = useDispatch();
  const courseData = useSelector(state => state.course);

  React.useEffect(() => {
    dispatch(getCourses());
  }, []);

  return (
    <>
      <div className="main">
        <Layout pages={
          [
            <div
              className="section section-courses" id="section-courses"
            >
              <Container>
                <div className="content-center" style={{ margin: "50px" }}>
                  <h3 className="presentation-subtitle text-center" style={{ color: "#ee8c1c" }}>Kurslar</h3>
                </div>

                {courseData.courses.length > 0 &&
                  <Row>
                    {
                      courseData?.courses?.map((x) => <Col className="mx-auto" lg="4" md="6" key={`c${x?.id}`}>
                        <CardBlog src={x?.imageURL} id={x?.id} title={x?.title} description={x?.body} isBlog={false} />
                      </Col>)
                    }
                  </Row>
                }

              </Container>
            </div>
          ]
        }></Layout>
      </div>
    </>
  );
}

export default CoursesPage;