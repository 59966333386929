
import React from "react";
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import {
//     doLogin,
// } from '../features/account/accountSlice';
// reactstrap components
import { login } from '../store/actions/loginActions';
import { Button, Card, Form, Input, Container, Row, Col } from "reactstrap";

// core components
import CustomNavbar from "../components/Navbars/CustomNavbar.js";
import background from "../assets/img/piano-background.jpg";

import { enumPages } from "../constants/pageTypes";

const LoginPage = () => {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountData = useSelector(state => state.account);
  const { message } = accountData.data;

  React.useEffect(() => {
    if (accountData.isAuthenticated === true)
      navigate('/');
  }, [accountData]);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      login: '',
      password: ''
    }
  });

  const onSubmit = (data) => {
    dispatch(login(data));
  };

  return (
    <>
      <CustomNavbar type={enumPages.SIGNED_OUT} />
      <div
        className="page-header"
        style={{
          backgroundImage:
            "url(" + background + ")",
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4">
              <Card className="card-register ml-auto mr-auto">
                <h3 className="title mx-auto">Punta Sanat</h3>
                <div className="social-line text-center">
                </div>
                <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
                  <label htmlFor="login">E-mail</label>
                  <Controller
                    name="login"
                    control={control}
                    rules={{
                      required: true,
                      minLength: 5
                    }}
                    render={({ field }) => <Input
                      placeholder="E-mail"
                      invalid={errors.email}
                      type="login"
                      name="login"
                      autoComplete="off"
                      {...field}
                    />}
                  />
                  {errors.email?.type === 'required' && "E-mail alanı boş geçilemez."}

                  <label htmlFor="password">Şifre</label>
                  <Controller
                    name="password"
                    rules={{
                      required: true,
                      minLength: 6
                    }}
                    control={control}
                    render={({ field }) =>
                      <Input
                        placeholder="Şifre"
                        invalid={errors.password}
                        type="password"
                        name="password"
                        autoComplete="off"
                        {...field}
                      />
                    }
                  />
                  {errors.password?.type === 'required' && "Şifre alanı boş geçilemez."}
                  <Button type="submit" block className="btn-round" color="danger" >
                    Giriş yap
                  </Button>
                  <br />
                  <div style={{ color: "black" }}>
                    <p>
                      {Object.keys(errors).length > 0 &&
                        "E-mail veya şifre hatalı."
                      }
                    </p>
                    <p>
                      {message}
                    </p>
                  </div>
                </form>
                {/* <div className="forgot">
                                    <Button
                                        className="btn-link"
                                        color="danger"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Şifremi Unuttum
                                    </Button>
                                </div> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LoginPage;
