import React from 'react';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactLoading from 'react-loading';
import {
  Button,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { useNavigate } from 'react-router-dom';

import { createBlog, resetBlogData } from '../../../store/actions/blogActions';

import Layout from '../../Layout';

const CreateBlogPage = () => {
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [body, setBody] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const [imageSrc, setImageSrc] = React.useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blogData = useSelector(state => state.blog);


  React.useEffect(() => {
    setIsLoading(false);
    if (blogData.data.result === true) {
      dispatch(resetBlogData());
      navigate('/#section-blog');
    }
  }, [blogData]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setBody(convertToHTML(editorState.getCurrentContent()));
  }

  const getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = error => { };
  };

  const imageUploadCallback = file => new Promise(
    (resolve, reject) => getFileBase64(
      file,
      data => resolve({ data: { link: data } })
    )
  );

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      title: "",
      Image: "",
      file: ""
    }
  });

  const onSubmit = (data, e) => {
    setIsLoading(true);
    data.Image = e.target.file.files[0];
    dispatch(createBlog(data.title, body, data.Image));
  };

  return (
    <>
      <Layout pages={
        [
          <div className="section section-courses content-center" id="create-blog" key="create-blog">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="text-center">Blog Oluştur</h2>
                  <form className="blog-form" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md="12">
                        <label>Resim</label>
                        <InputGroup>
                          <Controller
                            name="file"
                            control={control}
                            render={({ field }) => <Input
                              type="file"
                              name="file"
                              accept="image/png, image/jpeg"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label>Başlık</label>
                        <InputGroup>
                          <Controller
                            name="title"
                            control={control}
                            rules={{
                              required: true,
                              minLength: 1
                            }}
                            render={({ field }) => <Input
                              placeholder="Başlık"
                              type="text"
                              name="title"
                              autoComplete="off"
                              {...field}
                            />}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "20px" }}>
                      <Col md="12">
                        <label>Açıklama</label>
                        <Editor editorStyle={{ border: "1px solid", minHeight: "300px" }}
                          toolbar={{
                            image: {
                              uploadCallback: imageUploadCallback,
                              previewImage: true,
                            },
                          }}
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChange}
                          placeholder="Yazınızı buraya yazın..."
                        />
                      </Col>
                    </Row>

                    <Row>
                      {isLoading ?
                        <Col className="ml-auto mr-auto" md="2">
                          <ReactLoading type="spin" color="#f5593d" height={'60%'} width={'60%'} />
                        </Col>
                        :
                        <Col className="ml-auto mr-auto" md="4">

                          <Button type="submit" className="btn-fill" color="danger" size="lg" style={{ width: "100%" }}>
                            Kaydet
                          </Button>

                        </Col>
                      }
                    </Row>
                  </form>
                </Col>
              </Row>

            </Container>
          </div>
        ]
      }></Layout>

    </>
  );
}

export default CreateBlogPage;