import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from "../../../store/actions/courseActions.js";
//sections
import Layout from "../../Layout.js";
//Components 
import CardBlog from "../../../components/CardBlog.js";

const CoursePage = () => {
  const dispatch = useDispatch();
  const courseData = useSelector(state => state.course);

  React.useEffect(() => {
    dispatch(getCourses());
  }, []);

  return (
    <>
      <div className="main">
        <Layout pages={
          [
            <div
              className="section section-blogs" id="section-blogs" key="section-blogs"
            >
              <Container>
                <h2 className="text-center" style={{ marginBottom: "50px" }}>Kurslar</h2>
                <HashLink smooth to="/create-course#create-course" style={{ color: "#FFF", fontWeight: 600 }}>
                  <Button className="btn-link mr-1 btn btn-warning" style={{ color: "#ee8c1c", marginBottom: "25px" }}>
                    <i className="fa fa-plus"></i> Yeni Kurs Oluştur
                  </Button>
                </HashLink>
                {courseData.courses.length > 0 &&
                  <Row>
                    {
                      courseData?.courses?.map((x) => <Col className="mx-auto" lg="4" md="6" key={`c${x?.id}`}>
                        <CardBlog src={x?.imageURL} id={x?.id} title={x?.title} description={x?.body} isBlog={false} />
                      </Col>)
                    }
                  </Row>
                }
              </Container>
            </div>
          ]
        }></Layout>
      </div>

    </>
  );
}

export default CoursePage;