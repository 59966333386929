import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
} from "reactstrap";
import { HashLink } from "react-router-hash-link";
import Gallery from "react-photo-gallery";
//sections
import Layout from "../../Layout.js";
import api from "../../../api/api.js";

const PhotosPage = () => {
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    api.get(`Photo`).then((res) => {
      console.log(res);
      // let photos = res.data.map(x => {
      //   return {
      //     src: x.url,
      //     width: 4,
      //     height: 3,
      //   }
      // });
      setItems(res.data)
    });
  }, []);

  return (
    <>
      <div className="main">
        <Layout pages={
          [
            <div
              className="section section-blogs" id="section-blogs" key="section-blogs"
            >
              <Container>
                <h2 className="text-center" style={{ marginBottom: "50px" }}>Fotoğraflar</h2>
                <HashLink smooth to="/create-photo" style={{ color: "#FFF", fontWeight: 600 }}>
                  <Button className="btn-link mr-1 btn btn-warning" style={{ color: "#ee8c1c", marginBottom: "25px" }}>
                    <i className="fa fa-plus"></i> Yeni Fotoğraf Yükle
                  </Button>
                </HashLink>

                {items.length > 0 &&
                  <Row>
                    {
                      items.map((x) =>
                        <Col className="mx-auto" lg="4" md="6" key={`c${x?.id}`}>
                          <HashLink smooth to={`/photo-page/${x.id}`} style={{ color: "#FFF", fontWeight: 600 }}>
                            <Card>
                              <img src={x.url} height={300} />
                            </Card>
                          </HashLink>
                        </Col>)
                    }
                  </Row>
                }

                {/* <Gallery photos={items} /> */}

              </Container>
            </div>
          ]
        }></Layout>
      </div>

    </>
  );
}

export default PhotosPage;