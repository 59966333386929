import { GET_VIDEOS, GET_VIDEO, CREATE_VIDEO, RESET_VIDEO_DATA, USER_VIDEOS, CREATE_VIDEO_COMMENT, RESET_SELECTED_VIDEO_DATA, LOADING } from '../types.js';
import api from '../../api/api.js';

export const resetVideoData = () => async dispatch => {
  let tmpData = {
    errors: null,
    message: null,
    result: [],
    succeeded: false,
  };

  dispatch({
    type: RESET_VIDEO_DATA,
    payload: tmpData
  });
}

export const resetSelectedVideoData = () => async dispatch => {
  dispatch({
    type: RESET_SELECTED_VIDEO_DATA,
    payload: null
  });
}

export const getVideos = (pageNumber, pageSize) => async dispatch => {
  try {
    const res = await api.get(`Video?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    console.log(res.data);
    dispatch({
      type: GET_VIDEOS,
      payload: res.data
    });
  }
  catch (e) {
    dispatch({
      type: GET_VIDEOS,
      payload: e.response.data,
    })
  }
}

export const getVideo = (id) => async dispatch => {
  try {
    const res = await api.get(`Video/${id}`);
    console.log('res.data');
    console.log(res.data);
    dispatch({
      type: GET_VIDEO,
      payload: res.data.result
    });
  }
  catch (e) {
    dispatch({
      type: GET_VIDEO,
      payload: e.response.data,
    });
  }
}

export const createVideo = (title, description, Video) => async dispatch => {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const res = await api.post(`Video`, { Title: title, Description: description, Video, VideoCode: null }, config);

    dispatch({
      type: CREATE_VIDEO,
      payload: res.data
    });
  }
  catch (e) {
    dispatch({
      type: CREATE_VIDEO,
      payload: e.response.data,
    })
  }
}

export const createVideoComment = (id, videoComment) => async dispatch => {
  try {
    const res = await api.post(`Video/comment/${id}?videoComment=${videoComment}`);
    dispatch(getVideo(id));
    dispatch({
      type: CREATE_VIDEO_COMMENT,
      payload: res.data
    });
  }
  catch (e) {
    dispatch({
      type: CREATE_VIDEO_COMMENT,
      payload: e.response.data,
    })
  }
}

export const userVideos = (pageNumber, pageSize) => async dispatch => {
  try {
    const res = await api.get(`User/videos?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    dispatch({
      type: USER_VIDEOS,
      payload: res.data
    });
  }
  catch (e) {
    dispatch({
      type: USER_VIDEOS,
      payload: e.response.data,
    })
  }
}

export const loading = () => async dispatch => {
  dispatch({
    type: LOADING,
    payload: true
  });
}