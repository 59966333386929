import React from "react";

import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from "reactstrap";
import { HashLink } from 'react-router-hash-link';
import background from "../assets/img/piano-background.jpg";

const CardBlog = (props) => {
  return (
    <div>
      <Card>
        <CardImg top style={{ height: '225px' }} src={props.src ? props.src : background} alt="Card image cap" />
        <CardBody>
          <CardTitle><h5>{props.title?.length > 22 ? props.title?.substring(0, 22) + "..." : props.title}</h5></CardTitle>
          <CardText style={{ minHeight: "150px" }}>
            <div dangerouslySetInnerHTML={{ __html: (props.description?.length > 165) ? props.description?.substring(0, 165) + "..." : props.description }} />
          </CardText>
          <HashLink smooth to={(props.isBlog) ? `/blog/${props.id}#blog-page` : `/course/${props.id}#course-page`} style={{ color: "#FFF", fontWeight: 600 }}>
            <Button className="btn-link mr-1 btn btn-warning" style={{ color: "#ee8c1c", position: "absolute", bottom: "20px" }}>Devamını Oku <i className="fa fa-arrow-right"></i></Button>
          </HashLink>
        </CardBody>
      </Card>
    </div>
  )
}

export default CardBlog;